import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { LargeP, FlexList, FlexItem } from '../elements'
import RequestQuote from '../components/RequestQuote'

const NationwideServicePage = ({ data }) => {
  const postNode = {
    title: `Nationwide Service - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Nationwide Service - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <ServiceContainer>
        <PageTitle>Nationwide Service</PageTitle>
        <LargeP>
          Chuck pig cow porchetta, andouille biltong tri-tip turkey rump ham
          hock turducken sirloin salami. Short loin landjaeger bacon cupim,
          biltong porchetta venison cow ham hock.
        </LargeP>
        <p>
          Chuck pig cow porchetta, andouille biltong tri-tip turkey rump ham
          hock turducken sirloin salami. Short loin landjaeger bacon cupim,
          biltong porchetta venison cow ham hock. Spare ribs ham hock beef, pork
          chop jowl hamburger cupim biltong landjaeger prosciutto. Chicken rump
          beef ribs tri-tip meatloaf, brisket strip steak shankle. Short loin
          t-bone meatloaf rump venison.
        </p>
        <h4>Heading</h4>
        <p>
          Chuck pig cow porchetta, andouille biltong tri-tip turkey rump ham
          hock turducken sirloin salami. Short loin landjaeger bacon cupim,
          biltong porchetta venison cow ham hock. Spare ribs ham hock beef, pork
          chop jowl hamburger cupim biltong landjaeger prosciutto. Chicken rump
          beef ribs tri-tip meatloaf, brisket strip steak shankle. Short loin
          t-bone meatloaf rump venison.
        </p>
        <h4>Heading</h4>
        <p>
          Chuck pig cow porchetta, andouille biltong tri-tip turkey rump ham
          hock turducken sirloin salami. Short loin landjaeger bacon cupim,
          biltong porchetta venison cow ham hock. Spare ribs ham hock beef, pork
          chop jowl hamburger cupim biltong landjaeger prosciutto. Chicken rump
          beef ribs tri-tip meatloaf, brisket strip steak shankle. Short loin
          t-bone meatloaf rump venison.
        </p>
        <h4>Heading</h4>
        <p>
          Chuck pig cow porchetta, andouille biltong tri-tip turkey rump ham
          hock turducken sirloin salami. Short loin landjaeger bacon cupim,
          biltong porchetta venison cow ham hock. Spare ribs ham hock beef, pork
          chop jowl hamburger cupim biltong landjaeger prosciutto. Chicken rump
          beef ribs tri-tip meatloaf, brisket strip steak shankle. Short loin
          t-bone meatloaf rump venison.
        </p>
        <h4>Heading</h4>
        <p>
          Chuck pig cow porchetta, andouille biltong tri-tip turkey rump ham
          hock turducken sirloin salami. Short loin landjaeger bacon cupim,
          biltong porchetta venison cow ham hock. Spare ribs ham hock beef, pork
          chop jowl hamburger cupim biltong landjaeger prosciutto. Chicken rump
          beef ribs tri-tip meatloaf, brisket strip steak shankle. Short loin
          t-bone meatloaf rump venison.
        </p>
        <h4>Benefits</h4>
        <FlexList>
          <FlexItem>Benefit Here</FlexItem>
          <FlexItem>Benefit Here</FlexItem>
          <FlexItem>Benefit Here</FlexItem>
          <FlexItem>Benefit Here</FlexItem>
          <FlexItem>Benefit Here</FlexItem>
          <FlexItem>Benefit Here</FlexItem>
          <FlexItem>Benefit Here</FlexItem>
          <FlexItem>Benefit Here</FlexItem>
          <FlexItem>Benefit Here</FlexItem>
          <FlexItem>Benefit Here</FlexItem>
          <FlexItem>Benefit Here</FlexItem>
        </FlexList>
        <RequestQuote />
      </ServiceContainer>
    </Layout>
  )
}

export default NationwideServicePage
